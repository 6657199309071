<script setup lang="ts">
  // https://github.com/vueuse/head
  // you can use this to manipulate the document head in any components,
  // they will be rendered correctly in the html results with vite-ssg
  import { useUsersnapApi } from '@/modules/usersnap'
  import { useScreenBreakpoints } from '@/use/useScreenBreakpoints'
  import { hideZendeskWidget, showZendeskWidget, useZendeskWidget } from '@/use/useZendeskWidget'

  useHead({
    title: 'SmartMLS Sign',
    meta: [
      { name: 'description', content: 'Smart Signature Platform' },
      { name: 'color-scheme', content: 'light only' },
      { name: 'darkreader-lock' }
    ]
  })

  const route = useRoute()
  const screen = useScreenBreakpoints()

  if (screen.sm) {
    const usersnapApi = useUsersnapApi()
    watch(usersnapApi, () => {
      if (!usersnapApi.value) return

      usersnapApi.value.destroy().finally(() => {
        usersnapApi.value.init({
          user: { email: String(route.query.email ?? '') }
        })
      })
    }, { immediate: true })
  }

  useZendeskWidget()

  // show Zendesk for lg screens and bigger
  if (screen.lg) {
    showZendeskWidget()
  }
  watch(() => screen.lg, () => {
    if (screen.lg) {
      showZendeskWidget()
    } else {
      hideZendeskWidget()
    }
  })
</script>

<template>
  <SystemReload />

  <NatNotifications>
    <RouterView />
  </NatNotifications>
</template>
