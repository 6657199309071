import '@/modules/dayjs'
import { UserModuleParams } from '@/UserModule'
import { install as head } from '@/modules/head'
import { install as i18n } from '@/modules/i18n'
import { install as nprogress } from '@/modules/nprogress'
import { install as pwa } from '@/modules/pwa'
import { install as query } from '@/modules/query'
import { install as rollbar } from '@/modules/rollbar'
import { install as signingAuth } from '@/modules/signingAuth'
import { install as usersnap } from '@/modules/usersnap'

export const initSigningApp = (params: UserModuleParams) => {
  [signingAuth, head, i18n, query, nprogress, pwa, usersnap, rollbar].forEach((install) => install(params))
}
