<script setup lang="ts">
  import dayjs from 'dayjs'

  type Props = {
    countdownDate: string // Time left for countdown in ms
    withMinutes?: boolean
    withHours?: boolean
    timeUnitZeroLead?: boolean
  }
  const props = withDefaults(defineProps<Props>(), {
    withMinutes: true,
    withHours: true,
    timeUnitZeroLead: true
  })

  type Emits = {
    (e: 'finish'): void
  }
  const emit = defineEmits<Emits>()

  const unitSize = props.timeUnitZeroLead ? 2 : 1

  const now = useNow({ interval: 100 })
  const countdownDate = computed(() => dayjs(props.countdownDate))

  const secondsLeft = computed(() => {
    const leftInSeconds = countdownDate.value.diff(dayjs(now.value), 'seconds')
    return Math.max(leftInSeconds, 0)
  })
  const secondsLeftTimer = computed(() => String(secondsLeft.value % 60).padStart(unitSize, '0'))

  const minutesLeft = computed(() => Math.floor(secondsLeft.value / 60))
  const minutesLeftTimer = computed(() => String(minutesLeft.value % 60).padStart(unitSize, '0'))

  const hoursLeft = computed(() => Math.floor(minutesLeft.value / 60))
  const hoursLeftTimer = computed(() => String(hoursLeft.value % 24).padStart(unitSize, '0'))

  watch(secondsLeft, (left) => {
    if (left <= 0) emit('finish')
  })
</script>

<template>
  <div class="flex gap-1">
    <slot name="label" />

    <template v-if="withHours">
      <div class="w-1.3em text-center">
        {{ hoursLeftTimer }}
      </div>
      <div>:</div>
    </template>

    <template v-if="withMinutes">
      <div class="w-1.3em text-center">
        {{ minutesLeftTimer }}
      </div>
      <div>:</div>
    </template>

    <div class="w-1.3em text-center">
      {{ secondsLeftTimer }}
    </div>
  </div>
</template>
