import { loadScript } from '@/utils/utils'

let isInitialized = false

/**
 * Use to initialize and customize ZenDesk widget.
 * You can control Zendesk widget with showZendeskWidget and hideZendeskWidget functions
 *
 * !!! Zendesk widget is hidden by default
 */
export const useZendeskWidget = () => {
  onMounted(() => {
    const key = import.meta.env.VITE_ZENDESK_KEY
    if (!key || isInitialized) return
    isInitialized = true

    loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${key}`, 'ze-snippet')
      .then(() => {
        // Check every 25 milliseconds while zendesk loaded. It does not have loaded event
        // The interval is so small to prevent appearing of helper tooltip button

        const customLauncher = document.querySelector<HTMLDivElement>('.customLauncher')
        if (customLauncher) {
          customLauncher.addEventListener('click', () => {
            (window as any).zE('messenger', 'open')
          });
          (window as any).zE('messenger:on', 'close', () => {
            customLauncher.style.display = 'flex'
          });
          (window as any).zE('messenger:on', 'open', () => {
            customLauncher.style.display = 'none'
          })
        }
      })
  })
}

export const showZendeskWidget = () => {
  const customLauncher = document.querySelector<HTMLDivElement>('.customLauncher')
  if (customLauncher) {
    customLauncher.style.display = 'flex'
  }
}

export const hideZendeskWidget = () => {
  const customLauncher = document.querySelector<HTMLDivElement>('.customLauncher')
  if (customLauncher) {
    customLauncher.style.display = 'none'
  }
}
