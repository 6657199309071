import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import { routes } from './signOffRoutes'

export const makeSignOffRouter = () => createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,
  history: import.meta.env.DEV
    ? createWebHashHistory()
    : createWebHistory()
})
