import { RouteRecordRaw } from 'vue-router'

import EmptyRouterView from '@/pages/EmptyRouterView.vue'
const Distribute = () => import('@/pages/Signing/Distribute.vue')
const Signing = () => import('@/pages/Signing/Signing.vue')
const Download = () => import('@/pages/Signing/Download/Download.vue')
const SigningReject = () => import('@/pages/Signing/SigningReject/SigningReject.vue')

export const routes: RouteRecordRaw[] = [
  {
    path: '/signing/:id',
    name: 'Home',
    component: EmptyRouterView,
    children: [
      {
        path: 'review',
        name: 'Review',
        component: Signing
      },
      {
        path: 'sign',
        name: 'Sign',
        component: Signing
      },
      {
        path: 'distribute',
        name: 'Distribute',
        component: Distribute
      },
      {
        path: 'download',
        name: 'Download',
        component: Download
      },
      {
        path: 'reject',
        name: 'Reject',
        component: SigningReject
      }
    ]
  },
  {
    path: '/verify',
    name: 'VerifySMS',
    component: () => import('@/pages/Verify/VerifySMS.vue')
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: 'Error',
    component: () => import('@/pages/Error/Error.vue')
  }
]
