/* eslint-disable import/order */
import '@unocss/reset/tailwind.css'
import '@/styles/main.css'
import 'uno.css'
import VueGtag from 'vue-gtag'

import { initSigningApp } from '@/initSigningApp'
import { makeSignOffRouter } from '@/router/makeSignOffRouter'
// import { mockApi } from '@/api/apiMocks'

import MainSigning from './MainSigning.vue'
import { gtagTrackDirective } from '@/utils/gtagTrackDirective'

const app = createApp(MainSigning) // createSSRApp(Main)

const router = makeSignOffRouter()
app.use(router)

const googleAnalyticsID = import.meta.env.VITE_GOOGLE_ANALYTICS_FLOW_KEY
if (googleAnalyticsID) {
  app.use(VueGtag, { config: { id: googleAnalyticsID } }, router)
}

app.directive('gtag', gtagTrackDirective)

initSigningApp({ app, router, initialState: window.__INITIAL_STATE__ ?? {} })

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  // mockApi()
  app.mount('#app')
})
