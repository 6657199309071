/**
 * These codes are allowed to be returned in API calls,
 * getting them will not cause any more retries, and no
 * Rollbar errors will be fired.
 */
export const allowedNonRecoverableHttpStatusCodes = [401, 403, 409]

/**
 * Number of retry attempts, for unsuccessful API calls.
 */
export const defaultRetries = Number(import.meta.env.VITE_COUNT_RETRY_REQUEST) || 3

export class ResponseError extends Error {
  readonly status: number
  readonly data: object

  constructor ({ message, status, data }: { message: string, status: number, data: object }) {
    super(message)

    this.status = status
    this.data = data
  }
}
