import { Md5 } from 'ts-md5'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { unwrapArray } from '@/utils/utils'

export let activeParticipantHash: string

/**
 * Redirect to localhost if dev mode needed
 */
export const useLocalSigning = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (localStorage.getItem('useRedirectToLocal')) {
    window.location.href = `http://localhost:3333/signing/#${to.fullPath}`
    return
  }

  next()
}

/**
 * Set User unique key "activeParticipantHash" calculated by hash(email + participantID)
 */
export const setUserHash = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const email = unwrapArray(to.query.email)
  const participantId = unwrapArray(to.query.participantId)
  if (!email || !participantId) return next()

  activeParticipantHash = Md5.hashStr(email + participantId)
  next()
}

/**
 * Set secret key for each User by "activeParticipantHash" as localStorage key
 */
export const setSecretKey = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const key = unwrapArray(to.query.key)
  if (!key || !activeParticipantHash) return next()

  localStorage.setItem(activeParticipantHash, key)
  next({ ...to, query: { ...to.query, key: undefined } })
}

/**
 * Redirect User to SMS verification if "verify" query param in URL
 */
export const verifySmsCode = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const verify = unwrapArray(to.query.verify)
  if (verify !== 'true') return next()

  next({ name: 'VerifySMS', query: getVerifyQueryParams(to) })
}

const getVerifyQueryParams = (to: RouteLocationNormalized) => {
  // Route name to redirect after sms authentication
  const action = String(to.name)

  // Signing id
  const id = to.params.id

  // Query params, user data
  const participantId = unwrapArray(to.query.participantId)
  const email = unwrapArray(to.query.email)
  const phoneNumberLast4Digits = unwrapArray(to.query.phoneNumberLast4Digits)

  return {
    action,
    id,
    participantId,
    email,
    phoneNumberLast4Digits
  }
}
