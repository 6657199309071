import { UserModule } from '@/UserModule'
import { api } from '@/api/api'
import { UserInfo } from '@/api/apiTypes'
import { i18n } from '@/modules/i18n'
import {
  activeParticipantHash,
  setSecretKey,
  setUserHash,
  useLocalSigning,
  verifySmsCode
} from '@/router/guards/signOffGuards'
import { useUserState } from '@/use/useUserState'
import { ResponseError } from '@/utils/responseError'
import { isObject } from '@/utils/typecheck'
import { unwrapArray } from '@/utils/utils'

type SigningRejectQuery = 'message' | 'component' | 'participantId'

enum ReviewCodes {
  PARTICIPANT_PHONE_NOT_VERIFIED = 'ParticipantPhoneNumberNotVerified',
  PARTICIPANT_KEY_EXPIRED = 'ParticipantKeyExpired'
}

export const install: UserModule = ({ router }) => {
  let activeParticipantId: number | undefined

  router.beforeEach((to, from, next) => {
    activeParticipantId = Number(unwrapArray(to.query.participantId))
    return setUserHash(to, from, next)
  })
  router.beforeEach(useLocalSigning)
  router.beforeEach(setSecretKey)
  router.beforeEach(verifySmsCode)
  const { setUser } = useUserState()

  api.addRequestInterceptor((req) => {
    const participantSecretKey = localStorage.getItem(activeParticipantHash)
    if (!participantSecretKey) return req

    req.headers = {
      ...req.headers,
      'X-GOSIGN-PARTICIPANT-KEY': participantSecretKey
    }
    return req
  })

  api.addResponseInterceptor(async (res) => {
    const participantSecretKey = localStorage.getItem(activeParticipantHash)

    if (
      (res.status === 401 || res.status === 403) &&
        activeParticipantId !== undefined &&
        participantSecretKey !== null &&
        isObject(res.data) &&
        'code' in res.data &&
        (res.data.code === ReviewCodes.PARTICIPANT_KEY_EXPIRED ||
        res.data.code === ReviewCodes.PARTICIPANT_PHONE_NOT_VERIFIED)
    ) {
      if ('user' in res.data) {
        setUser(res.data.user as UserInfo)
      }

      const t = i18n?.global.t as (msg: string, options?: Record<string, string | number>) => string

      let query: Partial<Record<SigningRejectQuery, string>>

      switch (res.data.code) {
        case ReviewCodes.PARTICIPANT_KEY_EXPIRED:
          query = {
            participantId: String(activeParticipantId),
            component: 'resend',
            message: t('signing-link-has-expired')
          }
          break
        case ReviewCodes.PARTICIPANT_PHONE_NOT_VERIFIED:
          query = {
            message: t('signing-has-been-resent-to-a-new-email-address')
          }
          break
        default:
          query = {
            message: t('error')
          }
          break
      }

      await router.replace({
        name: 'Reject',
        query
      })

      throw new ResponseError({
        message: `${t('error')}: ${res.data.code}`,
        status: res.status,
        data: res.data
      })
    }

    // TODO: 401 response can't be read because of CORS
    if (res.status === 401) {
      router.push({ name: 'Error' })
    }
    return res
  })
}
