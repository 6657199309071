<script setup lang="ts">
  import dayjs from 'dayjs'

  import { isObject } from '@/utils/typecheck'

  type ReloadInfoWsMessage = {
    type: 'update'
    message?: string
    time?: string
    status?: 'done'
  }

  type Props = {
    updateTimerClass?: string
  }
  defineProps<Props>()

  const { t } = useI18n()

  const maintenanceEnabled = import.meta.env.VITE_MAINTENANCE_DISABLED !== 'true'
  const wsUrl = import.meta.env.VITE_WS_SYSTEM_NOTIFICATION_URL
  // Ui timings
  const disableUiTimeoutMs = Number(import.meta.env.VITE_DISABLE_UI_TIMEOUT_SECONDS || '60') * 1000
  const reloadAppTimeoutMs = Number(import.meta.env.VITE_RELOAD_APP_TIMEOUT_SECONDS || '10') * 1000
  // WebSocket timings
  const reconnectTimeoutMs = Number(import.meta.env.VITE_WS_RECONNECT_MS || '10000')
  const warmupIntervalMs = Number(import.meta.env.VITE_WS_WARMUP_INTERVAL_MS || '30000')

  let webSocket: WebSocket | null = null
  let reconnectAttempt = 0
  let reconnectTimeout: any = null
  let warmupInterval: any = null

  const connectToSocket = () => {
    try {
      if (!maintenanceEnabled) {
        console.info('Maintenance is disabled in settings')
        return
      }

      if (!wsUrl) {
        console.error('WebSocket connection error - URL required')
        return
      }

      console.log('Using %s for notifications', wsUrl)

      webSocket = new WebSocket(wsUrl)
      if (warmupInterval) {
        clearInterval(warmupInterval)
      }
      warmupInterval = setInterval(() => {
        try {
          webSocket?.send('ping')
        } catch (e) {
          console.warn('failed to send ping message', e)
        }
      }, warmupIntervalMs)

      webSocket.onmessage = (event: MessageEvent) => {
        console.log('onmessage', event.data)
        handleReloadAppScheduleMessage(event.data)
        handleReloadAppTriggerMessage(event.data)
      }

      webSocket.onclose = () => {
        console.info('WS reconnect try - ' + ++reconnectAttempt)
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout)
        }
        reconnectTimeout = setTimeout(connectToSocket, reconnectTimeoutMs)
      }

      webSocket.onerror = (err: Event) => {
        console.error(`[WebSocket error]: ${err}`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const uiDisabled = ref(false)
  const showCountdown = ref(false)
  const maintenanceTime = useLocalStorage<string>('maintenanceTime', '')
  const maintenanceMessage = useLocalStorage<string>('maintenanceMessage', '')
  const msLeftBeforeUpdate = ref(0)
  const maintenanceBeginDate = ref()
  const minutesLeftBeforeUpdate = computed(() => {
    return Math.max(dayjs(maintenanceTime.value).diff(dayjs(), 'minute'), 0)
  })
  const showMaintenanceBeginInTimer = computed<boolean>(() => {
    if (!maintenanceTime.value) return false
    return msLeftBeforeUpdate.value > 0 && msLeftBeforeUpdate.value < disableUiTimeoutMs
  })

  const updateUI = () => {
    if (!maintenanceTime.value || !maintenanceEnabled) return

    const diff = dayjs(maintenanceTime.value).diff(dayjs(), 'milliseconds')
    console.log('diff', diff)
    msLeftBeforeUpdate.value = Math.max(diff, 0)
    maintenanceBeginDate.value = dayjs().add(msLeftBeforeUpdate.value + 1_000, 'milliseconds')

    if (msLeftBeforeUpdate.value <= 0) {
      // update already started
      console.log('Maintenance is in progress. Disable UI.')
      disableUi()
      return
    }

    const timeLeftMs = msLeftBeforeUpdate.value
    if (timeLeftMs < disableUiTimeoutMs) {
      // update is about to start
      console.log('Maintenance is about to begin. Disable UI.')
      disableUi()
      return
    }

    if (timeLeftMs > 0) {
      const disableUiInMs = timeLeftMs - disableUiTimeoutMs
      console.log('Maintenance will start %dms. Will disable UI in %dms', timeLeftMs, disableUiInMs)
      setTimeout(updateUI, disableUiInMs)
    }
  }

  const handleReloadAppScheduleMessage = (reloadInfoMessageData: any) => {
    try {
      const reloadInfo = JSON.parse(reloadInfoMessageData)
      if (isObject(reloadInfo) && 'type' in reloadInfo && 'time' in reloadInfo) {
        const {
          type,
          time,
          message
        } = reloadInfo as ReloadInfoWsMessage
        if (type !== 'update' || !time) return

        maintenanceTime.value = time
        maintenanceMessage.value = message
        updateUI()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const closeAlertHandler = () => {
    showCountdown.value = true
  }

  const showReloadCountdown = ref(false)
  const reloadAppDate = ref('')
  const startReloadAppTimer = () => {
    showReloadCountdown.value = true
    reloadAppDate.value = dayjs().add(reloadAppTimeoutMs + 1_000, 'milliseconds').toISOString()
  }

  const handleReloadAppTriggerMessage = (reloadInfoMessageData: any) => {
    try {
      const reloadInfo = JSON.parse(reloadInfoMessageData)
      if (isObject(reloadInfo) && 'type' in reloadInfo && 'status' in reloadInfo) {
        const {
          type,
          status,
          message
        } = reloadInfo as ReloadInfoWsMessage
        if (type !== 'update' && status !== 'done') return

        maintenanceMessage.value = message
        console.log('Maintenance is DONE ', message)
        disableUi()
        startReloadAppTimer()
        maintenanceTime.value = ''
      }
    } catch (e) {
      console.error(e)
    }
  }

  const disableUi = () => {
    if (!maintenanceTime.value) return

    document.body.style.overflow = 'hidden'
    uiDisabled.value = true
  }

  const reloadApp = () => {
    maintenanceTime.value = ''
    maintenanceMessage.value = ''
    window.location.reload()
  }

  onMounted(updateUI)

  const cleanUpWs = () => {
    if (reconnectTimeout) clearTimeout(reconnectTimeout)
    if (warmupInterval) clearInterval(warmupInterval)
    if (webSocket) {
      webSocket.close()
      webSocket = null
    }
  }

  onUnmounted(cleanUpWs)
  window.onbeforeunload = cleanUpWs

  connectToSocket()
</script>

<template>
  <!--  App update schedule message  -->
  <Teleport to="body">
    <NatFadeTransition>
      <div
        v-if="maintenanceTime && msLeftBeforeUpdate > disableUiTimeoutMs && !showCountdown"
        class="fixed top-0 left-0 right-0 z-system-reload_alert flex items-center justify-center mt-2"
      >
        <div class="flex gap-4 items-center bg-danger rounded-10px px-4 py-2 lg:max-w-200">
          <IconGoWarning class="w-9 h-9 text-yellow inline-block" />
          <div class="text-white text-center">
            {{ t('application-will-be-updated-in-left-minutes', { left: minutesLeftBeforeUpdate }) }}.
            {{ t('please-ensure-your-work-is-saved-and-be-ready-for-reloading-the-current-page') }}.
            <template v-if="maintenanceMessage">
              <br>{{ maintenanceMessage }}
            </template>
          </div>

          <NatTooltip placement="right">
            <template #reference="{ ref, popoverId }">
              <NatButton
                :ref="ref"
                :aria-describedby="popoverId"
                class="flex-shrink-0 w-6 h-6"
                semantics="secondary"
                rounded
                @click="closeAlertHandler"
              >
                <IconGoClose class="w-3 h-3" />
              </NatButton>
            </template>
            {{ t('close-alert') }}
          </NatTooltip>
        </div>
      </div>
    </NatFadeTransition>
  </Teleport>

  <!--  Update time left timer  -->
  <Teleport to="body">
    <div
      v-if="showCountdown && msLeftBeforeUpdate > disableUiTimeoutMs"
      class="fixed top-0 left-0 right-0 z-1 w-fit m-2.5"
      :class="updateTimerClass"
    >
      <TimerCountdown
        class="text-6 bg-danger rounded-10px px-3 py-1 text-white"
        :countdownDate="maintenanceTime"
      />
    </div>
  </Teleport>

  <!--  UI block before update  -->
  <Teleport to="body">
    <div
      v-if="uiDisabled"
      class="cursor-not-allowed fixed top-0 bottom-0 left-0 right-0 z-system-reload_ui-blocked flex justify-center items-center bg-black bg-opacity-50"
    >
      <div class="flex flex-col gap-2 justify-center items-center">
        <TimerCountdown
          v-if="showReloadCountdown"
          class="text-16 text-white"
          :countdownDate="reloadAppDate"
          :withHours="false"
          :withMinutes="false"
          :timeUnitZeroLead="false"
          @finish="reloadApp"
        >
          <template #label>
            <div class="mr-2">
              {{ t('reloading-page-in') }}
            </div>
          </template>
        </TimerCountdown>

        <TimerCountdown
          v-if="showMaintenanceBeginInTimer"
          class="text-16 text-white"
          :countdownDate="maintenanceBeginDate"
          :withHours="false"
          :withMinutes="false"
          :timeUnitZeroLead="false"
          @finish="updateUI"
        >
          <template #label>
            <div class="mr-2">
              {{ t('maintenance-begin-in') }}
            </div>
          </template>
        </TimerCountdown>

        <div class="text-white text-center text-6">
          <template v-if="maintenanceTime && msLeftBeforeUpdate <= 0">
            {{ t('maintenance-in-progress') }}
          </template>
          <template v-if="maintenanceMessage">
            <br>{{ maintenanceMessage }}
          </template>
          <template v-if="maintenanceTime && msLeftBeforeUpdate <= 0">
            {{ t('please-do-no-reload-page') }}
          </template>
        </div>

        <NatButton
          v-if="showReloadCountdown"
          class="text-6.5 px-4 w-60 mt-5"
          :label="t('reload-now')"
          semantics="accent"
          @click="reloadApp"
        />
      </div>
    </div>
  </Teleport>
</template>
