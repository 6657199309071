import { UserInfo } from '@/api/apiTypes'

export const useUserState = createGlobalState(() => {
  const userInfo = ref<UserInfo | undefined>()

  const setUser = (user: UserInfo) => {
    userInfo.value = user
  }

  return {
    userInfo,
    setUser
  }
})
